@charset "UTF-8";
* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

h1 {
  font-size: 1.4em;
}

.square20 {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 5px;
  vertical-align: top;
}

header {
  background-color: #444;
  color: #bbb;
  padding: 0px;
  display: flex;
  height: 40px;
  justify-content: space-between;
  border-bottom: 1px solid #666;
  position: relative;
  z-index: 20;
}
header a {
  color: #bbb;
}
header a:hover {
  color: #fff;
}
header .collapse-button {
  padding-left: 10px;
  padding-right: 10px;
  border-right: 1px solid #666;
}
header .collapse-button a {
  width: 50px;
  height: 40px;
  display: block;
  text-align: center;
  line-height: 40px;
}
header .title h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1em;
  line-height: 40px;
  color: #fff;
}
header .exit {
  line-height: 40px;
  padding-right: 20px;
  padding-left: 20px;
  border-left: 1px solid #666;
}
header .exit a {
  font-size: 0.9em;
  color: #bbb;
  height: 40px;
  line-height: 40px;
  display: block;
  text-align: center;
}
header .exit a:hover {
  text-decoration: none;
  color: #fff;
}

.admin-content {
  min-height: 0;
  padding-left: 245px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.admin-content > .container-fluid {
  padding: 0px;
  margin: 0px;
}

.admin-nav-menu {
  position: absolute;
  z-index: 10;
  height: 100%;
  left: 0px;
  top: 0px;
  width: 235px;
  padding-top: 40px;
  background-color: #444;
  color: #bbb;
  transition: all 0.3s;
}
.admin-nav-menu ul, .admin-nav-menu li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.admin-nav-menu ul li {
  position: relative;
}
.admin-nav-menu ul li a {
  color: #aaa;
  display: block;
  padding: 10px 20px;
  border-bottom: 1px solid #555;
  background-color: #444;
  white-space: nowrap;
}
.admin-nav-menu ul li a span {
  padding-left: 1em;
}
.admin-nav-menu ul li a.parent {
  position: relative;
}
.admin-nav-menu ul li a.parent:after {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-top: 1px solid #bbb;
  border-right: 1px solid #bbb;
  transform: rotate(135deg);
  position: absolute;
  right: 12px;
  top: 20px;
  transition: transform 0.2s;
}
.admin-nav-menu ul li a.parent.closed:after {
  transform: rotate(45deg);
}
.admin-nav-menu ul li a.parent + ul {
  transition: all 0.2s;
  overflow: hidden;
}
.admin-nav-menu ul li a:hover {
  text-decoration: none;
  background-color: #555;
  color: #fff;
}
.admin-nav-menu ul li ul li a {
  padding-left: 2em;
  font-size: 0.9em;
  transition: all 0.2s;
}
.admin-nav-menu ul li ul li a:hover {
  padding-left: 3em;
}

body a.show-menu-ref {
  display: none;
}
body a.hide-menu-ref {
  display: block;
}

body.menu-full .admin-nav-menu ul li a.parent.closed + ul {
  height: 0 !important;
}

body.menu-collapsed a.show-menu-ref {
  display: block;
}
body.menu-collapsed a.hide-menu-ref {
  display: none;
}
body.menu-collapsed .admin-nav-menu {
  width: 70px;
}
body.menu-collapsed .admin-nav-menu ul li a {
  text-align: center;
}
body.menu-collapsed .admin-nav-menu ul li a span {
  display: none;
}
body.menu-collapsed .admin-nav-menu ul li a:after {
  display: none;
}
body.menu-collapsed .admin-nav-menu ul li a.open + ul {
  display: none;
}
body.menu-collapsed .admin-nav-menu ul li a.parent.closed + ul {
  transform: scaleX(0);
}
body.menu-collapsed .admin-nav-menu ul li ul {
  position: absolute;
  left: 70px;
  top: 0px;
  display: block;
  transform-origin: left;
}
body.menu-collapsed .admin-nav-menu ul li ul a {
  text-align: left;
}
body.menu-collapsed .admin-content {
  padding-left: 80px;
}

.image-album-wrapper {
  font-size: 0;
}
.image-album-wrapper > div {
  height: 158px;
  display: inline-block;
  padding: 4px;
  position: relative;
  cursor: move;
}
.image-album-wrapper > div .buttons {
  position: absolute;
  bottom: 4px;
  left: 4px;
  height: 34px;
  font-size: 18px;
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.7);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  -moz-border-radius-topright: 5px;
  -webkit-border-top-right-radius: 5px;
  border-top-right-radius: 5px;
  transition: all 0.2s;
}
.image-album-wrapper > div .buttons:hover {
  background-color: white;
}
.image-album-wrapper > div .buttons a {
  color: #000;
  display: inline-block;
  padding-left: 6px;
  padding-right: 6px;
  transition: all 0.2s;
}
.image-album-wrapper > div .buttons a.edit {
  color: #000;
}
.image-album-wrapper > div .buttons a.delete {
  color: #F00;
}
.image-album-wrapper > div .buttons a:hover {
  transform: translateY(3px);
}
.image-album-wrapper > div img {
  height: 150px;
  display: inline-block;
}

.admin-content {
  font-size: 14px;
}
.admin-content .btn {
  font-size: 1em;
}
.admin-content .tab-pane {
  padding-top: 1em;
}
.admin-content label {
  font-size: 0.8em;
  margin-bottom: 0.1em;
}
.admin-content label.custom-file-label {
  font-size: 1em;
  margin-bottom: 0px;
}
.admin-content .article-form-image {
  max-width: 500px;
}
.admin-content .article-form-image img {
  width: 100px;
  margin-top: 30px;
}
.admin-content ol.breadcrumb {
  font-size: 0.8em;
  padding: 0.4rem 1rem;
}
.admin-content .admin-btn-top {
  margin-bottom: 1em;
}
.admin-content .admin-btn-top a, .admin-content .admin-btn-top button {
  font-size: 0.8em;
  line-height: 1em;
  padding: 0.5rem 0.25rem;
  box-shadow: none;
}
.admin-content .admin-btn-top a i, .admin-content .admin-btn-top button i {
  font-size: 2em;
  margin-bottom: 0.2em;
}
.admin-content .admin-btn-top .btn {
  background-color: transparent;
  border: none;
  color: #000;
}
.admin-content .admin-btn-top .btn.btn-success i {
  color: #28a745;
}
.admin-content .admin-btn-top .btn.btn-primary i {
  color: #007bff;
}
.admin-content .admin-btn-top .btn.btn-danger i {
  color: #bd2130;
}
.admin-content .admin-btn-top .btn.btn-error i {
  color: #bd2130;
}
.admin-content .admin-btn-top .btn:hover {
  background-color: rgba(99, 99, 99, 0.1);
  color: #000;
}
.admin-content body.scrolled .admin-btn-top {
  position: fixed;
  bottom: 30px;
  left: 10px;
  width: 80px;
  height: auto;
}
.admin-content body.scrolled .admin-btn-top a, .admin-content body.scrolled .admin-btn-top button {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.admin-content .article-list,
.admin-content .category-list {
  font-size: 0.9em;
}
.admin-content .flexy-copy-ref {
  font-size: 1em;
  text-transform: lowercase;
}
.admin-content .flexy-autocopy {
  color: #999999;
}
.admin-content .flexy-autocopy.on {
  background-color: #28a745;
  color: #fff;
}
.admin-content .flexy-autocopy:hover {
  color: #000;
  background-color: transparent;
}
.admin-content .flexy-autocopy.on:hover {
  background-color: #28a745;
  color: #000;
}
.admin-content .btn-outline-secondary.focus,
.admin-content .btn-outline-secondary:focus,
.admin-content .form-control:focus,
.admin-content .btn:focus {
  box-shadow: none;
}
.admin-content #template-code {
  width: 100%;
  height: 1000px;
}
.admin-content .CodeMirror {
  height: auto;
}
.admin-content .flexy-table {
  width: 100%;
}
.admin-content .flexy-table thead th, .admin-content .flexy-table thead td {
  padding: 10px 18px;
  border-bottom: 1px solid #111;
  vertical-align: middle;
}
.admin-content .flexy-table tbody {
  /*
              tr.even td
              {
                  background-color: #F6F6F6;
              }
  */
}
.admin-content .flexy-table tbody th, .admin-content .flexy-table tbody td {
  padding: 8px 10px;
  vertical-align: middle;
  background: white;
  background: -moz-linear-gradient(90deg, white 0%, #efefef 100%);
  background: -webkit-linear-gradient(90deg, white 0%, #efefef 100%);
  background: -o-linear-gradient(90deg, white 0%, #efefef 100%);
  background: -ms-linear-gradient(90deg, white 0%, #efefef 100%);
  background: linear-gradient(180deg, white 0%, #efefef 100%);
}
.admin-content .flexy-table tbody tr:hover td {
  background: white;
  background: -moz-linear-gradient(90deg, white 0%, #e6e6e6 100%);
  background: -webkit-linear-gradient(90deg, white 0%, #e6e6e6 100%);
  background: -o-linear-gradient(90deg, white 0%, #e6e6e6 100%);
  background: -ms-linear-gradient(90deg, white 0%, #e6e6e6 100%);
  background: linear-gradient(180deg, white 0%, #e6e6e6 100%);
}
.admin-content .unpublished {
  color: #999;
}

#parameter-template {
  display: none;
}

.btn-outline-secondary,
.btn-outline-secondary:hover {
  border: 1px solid #ced4da;
}

.btn-success.focus, .btn-success:focus,
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

#article_type_parameters > .form-group {
  display: block;
}
#article_type_parameters > .form-group .form-group {
  display: inline-block;
  vertical-align: top;
  margin-right: 3px;
}
#article_type_parameters > .form-group .form-group .delete-parameter {
  margin-top: 22px;
}

.admin_checklist .form-check {
  font-size: 1rem;
  border-bottom: 1px solid #eee;
  padding: 0.5rem 0 0.5rem 2rem;
}

#apt_weekdays_weekdays > fieldset .form-group {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 0px;
}

#apt_weekdays_weekdays > fieldset .form-group input[type=text] {
  width: 70px;
  text-align: center;
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 0px;
  font-size: 14px;
}

.btn {
  border-radius: 0;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
}
.btn.btn-primary {
  color: #fff;
  background-color: #639fe0;
  border-color: #639fe0;
}
.btn.btn-primary:hover {
  color: #fff;
  background-color: #0062cc;
  border-color: #0062cc;
}
.btn.btn-danger {
  color: #ffffff;
  background-color: #d35978;
  border-color: #d35978;
}
.btn.btn-danger:hover {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn.btn-secondary {
  color: #373a3c;
  background-color: #fff;
  border-color: #ccc;
}
.btn.btn-secondary:hover {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.btn.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.btn.btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #419641;
}
.btn.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.btn.btn-info:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #2aabd2;
}
.btn.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}
.btn.btn-warning:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #eb9316;
}
.btn.btn-link {
  font-weight: normal;
  color: #0275d8;
  border-radius: 0;
}
.btn.btn-link:hover {
  color: #014c8c;
  text-decoration: underline;
  background-color: transparent;
}

.dataTables_wrapper > .row {
  margin-left: 0;
  margin-right: 0;
}
.dataTables_wrapper .datatable-row-id {
  display: none;
}
.dataTables_wrapper a:hover {
  text-decoration: none;
}
.dataTables_wrapper .pagination {
  font-size: 0.9em;
}
.dataTables_wrapper .dataTables_info {
  font-size: 0.9em;
}
.dataTables_wrapper .dt-buttons {
  font-size: 0.9em;
}
.dataTables_wrapper .dt-buttons .dropdown-menu {
  font-size: 1em;
  border-radius: 0;
  margin-top: 0;
}
.dataTables_wrapper table.dataTable tr th.select-checkbox,
.dataTables_wrapper table.dataTable tr td.select-checkbox {
  max-width: 20px;
}
.dataTables_wrapper table.dataTable tr.selected td.select-checkbox::after, .dataTables_wrapper table.dataTable tr.selected th.select-checkbox::after {
  margin-top: -27px;
  margin-left: -5px;
  text-shadow: none;
  font-size: 1.3rem;
  color: #777;
}
.dataTables_wrapper table.dataTable tbody tr.selected, .dataTables_wrapper table.dataTable tbody th.selected, .dataTables_wrapper table.dataTable tbody td.selected {
  color: #999;
}
.dataTables_wrapper table.dataTable tbody tr.selected a, .dataTables_wrapper table.dataTable tbody th.selected a, .dataTables_wrapper table.dataTable tbody td.selected a {
  color: #00adff;
}
.dataTables_wrapper table.dataTable tbody tr.selected a.btn, .dataTables_wrapper table.dataTable tbody th.selected a.btn, .dataTables_wrapper table.dataTable tbody td.selected a.btn {
  color: #fff;
}
.dataTables_wrapper .dataTables_info {
  text-align: right;
}
.dataTables_wrapper table.dataTable tbody td.select-checkbox::before,
.dataTables_wrapper table.dataTable tbody th.select-checkbox::before {
  font-family: "Font Awesome 5 Free";
  content: "";
  margin-top: -7px;
  margin-left: -4px;
  border: none;
  border-radius: 0;
  font-size: 16px;
  color: #000;
}
.dataTables_wrapper table.dataTable tr.selected td.select-checkbox::before,
.dataTables_wrapper table.dataTable tr.selected th.select-checkbox::before {
  display: none;
}
.dataTables_wrapper table.dataTable tr.selected td.select-checkbox::after,
.dataTables_wrapper table.dataTable tr.selected th.select-checkbox::after {
  font-family: "Font Awesome 5 Free";
  content: "";
  margin-top: -7px;
  margin-left: -4px;
  border: none;
  border-radius: 0;
  font-size: 16px;
  color: #000;
}

.inline-radio > div {
  padding: calc(.375rem + 1px) 0;
}
.inline-radio .form-check {
  margin-left: 0.3em;
  margin-right: 0.3em;
}
.inline-radio legend {
  margin-right: 1em;
}
.inline-radio > div,
.inline-radio .form-check,
.inline-radio legend {
  width: auto;
  float: left;
}

.js-datepicker {
  width: 200px;
  text-align: center;
}
.js-datepicker[data-type=time] {
  width: 100px;
}
.js-datepicker[data-type=date] {
  width: 150px;
}